import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size, color, ...props }) => (
	<svg width={size} height={size} viewBox="0 0 209.673 209.673" {...props}>
		<path
			fill={color}
			d="M173.075 29.203H36.599C16.418 29.203 0 45.626 0 65.812v78.05c0 20.186 16.418 36.608 36.599 36.608h136.477c20.18 0 36.598-16.422 36.598-36.608v-78.05c-.001-20.186-16.419-36.609-36.599-36.609zm21.598 114.658c0 11.915-9.689 21.608-21.598 21.608H36.599c-11.91 0-21.599-9.693-21.599-21.608v-78.05c0-11.915 9.689-21.608 21.599-21.608h136.477c11.909 0 21.598 9.693 21.598 21.608v78.05z"
		/>
		<path
			fill={color}
			d="M145.095 98.57L89.499 61.92a7.502 7.502 0 00-11.628 6.262v73.309a7.502 7.502 0 007.499 7.5 7.489 7.489 0 004.129-1.239l55.596-36.659a7.5 7.5 0 000-12.523zm-52.224 28.992V82.109l34.471 22.723-34.471 22.73z"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
