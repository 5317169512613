import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size = 14, color = "#fff", ...props }) => (
	<svg viewBox="0 -12 512 512" width={size} height={size} {...props}>
		<path
			fill={color}
			d="M134.043 488.031c-4.129 0-8.3-.804-12.281-2.453A31.92 31.92 0 01102 456.004V422c0-12.133-9.867-22-22-22-44.113 0-80-35.887-80-80V80C0 35.887 35.887 0 80 0h352c44.113 0 80 35.887 80 80v140c0 11.047-8.953 20-20 20s-20-8.953-20-20V80c0-22.055-17.945-40-40-40H80c-22.055 0-40 17.945-40 40v240c0 22.055 17.945 40 40 40 34.188 0 62 27.813 62 62v14.715l43.55-43.55C206.938 371.776 235.372 360 265.618 360H432c22.055 0 40-17.945 40-40 0-11.047 8.953-20 20-20s20 8.953 20 20c0 44.113-35.887 80-80 80H265.617c-19.562 0-37.949 7.617-51.781 21.45l-57.191 57.19c-6.137 6.137-14.29 9.391-22.602 9.391zM412 120c0-11.047-8.953-20-20-20H120c-11.047 0-20 8.953-20 20s8.953 20 20 20h272c11.047 0 20-8.953 20-20zm0 80c0-11.047-8.953-20-20-20H120c-11.047 0-20 8.953-20 20s8.953 20 20 20h272c11.047 0 20-8.953 20-20zm-80 80c0-11.047-8.953-20-20-20H120c-11.047 0-20 8.953-20 20s8.953 20 20 20h192c11.047 0 20-8.953 20-20zm0 0"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
