import React from "react"
import { Link } from "react-router-dom"
import oferta from "../static/docs/oferta.pdf"
import policy from "../static/docs/policy.pdf"
import { IconFacebook, IconInstagram, IconTelegram, IconVk, IconYoutube } from "../static/icons"

const Footer = () => (
	<footer id="page-footer">
		<div className="social-links">
			<div className="row ai-center">
				<div className="col-auto d-flex">
					<a
						href="https://vk.com/mindflex.online"
						target="_blank"
						rel="noreferrer"
						className="soc-link"
					>
						<IconVk size={20} />
					</a>
					<a
						href="https://www.instagram.com/mindflex.online/"
						target="_blank"
						rel="noreferrer"
						className="soc-link"
					>
						<IconInstagram size={20} />
					</a>
					<a
						href="https://www.youtube.com/channel/UCpiak4CT61WvajKD9r87gAw?guided_help_flow=3"
						target="_blank"
						rel="noreferrer"
						className="soc-link"
					>
						<IconYoutube size={20} />
					</a>
					<a
						href="https://www.facebook.com/mindflexcom/"
						target="_blank"
						rel="noreferrer"
						className="soc-link"
					>
						<IconFacebook size={20} />
					</a>
					<a
						href="https://t.me/mindflex_bot
"
						target="_blank"
						rel="noreferrer"
						className="soc-link"
					>
						<IconTelegram size={20} />
					</a>
				</div>

				<div className="col-auto">
					<div className="copyright mb-1 mt-3 mt-md-0">
						© 2020. MindFlex.online Все права сохранены.
					</div>
					<div className="d-flex flex-wrap">
						<Link to={policy} target="_blank" className="copyright copyright-link mb-1">
							Политика конфиденциальности
						</Link>

						<Link to={oferta} target="_blank" className="copyright copyright-link">
							Договор оферты
						</Link>
					</div>
				</div>
			</div>
		</div>
	</footer>
)

export default Footer
