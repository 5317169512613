import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import PlayBtn from "../../static/icons/PlayLessonButton"
import "./SearchResult.scss"

const SearchResult = ({ data }) => (
	<div className="smart-search bg-dop h5 f-700 bg-bg">
		<div className="list scroll-y">
			{data.map((i, index) => (
				<Link to={`/catalog/courses/${i.direction_id}`} className="item pos" key={index}>
					<PlayBtn className="pos-abs-y" />
					{i.name}
				</Link>
			))}
		</div>
	</div>
)
SearchResult.propTypes = {
	data: PropTypes.array,
}

export default SearchResult
