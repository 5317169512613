import PropTypes from "prop-types"
import React, { lazy, Suspense, useContext, useEffect, useRef } from "react"
import { Route, withRouter } from "react-router"
import Api from "./../../api"
import LeftMenu from "../../components/LeftMenu/LeftMenu"
import { AppContext } from "../../context/AppContext"
import { BODY } from "../../global/data"
import Footer from "../../layouts/Footer"
import Header from "../../layouts/Header"
import "./LkFlow.scss"

const Chat = lazy(() => import("./Chat"))

const Homework = lazy(() => import("./Testing/Homework"))
const ViewHomework = lazy(() => import("./Testing/Homework/View"))

const Test = lazy(() => import("./Testing/Test"))
const Quest = lazy(() => import("./Testing/Quest"))
const Questions = lazy(() => import("./Questions"))
const QuestionsIn = lazy(() => import("./Questions/QuestionIn"))

const Profile = lazy(() => import("./Profile/Edit"))
const ProfileDirections = lazy(() => import("./Profile/Directions"))
const ProfileCourses = lazy(() => import("./Profile/Courses"))
const ProfileModules = lazy(() => import("./Profile/Modules"))

const CatalogDirections = lazy(() => import("./Catalog/Directions"))
const CatalogCourses = lazy(() => import("./Catalog/Courses"))
const CatalogModules = lazy(() => import("./Catalog/Modules"))
const Lesson = lazy(() => import("./Lesson/Lesson"))

const LkFlow = ({ location }) => {
	const { state, setUnreadMessages } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const leftMenuRef = useRef()
	const toggleMobMenuBtnRef = useRef()

	const handleMenuOpen = e => {
		e.preventDefault()

		BODY.classList.toggle("_hidden")
		leftMenuRef.current.toggleMenu()
		toggleMobMenuBtnRef.current.toggleClass()
	}

	const getUnreadMessages = async () => {
		const res = await Api.get("/message/not-read", {
			headers: { Authorization },
		})
		const { data, error } = res.data

		if (!error) {
			setUnreadMessages(data)
		} else {
			console.error(data)
			alert("Ошибка")
		}
	}

	useEffect(() => {
		BODY.classList.remove("_hidden")

		BODY.scrollTop = 0

		leftMenuRef.current.hideMenu()
		toggleMobMenuBtnRef.current.removeClass()

		getUnreadMessages()
	}, [location.pathname])

	return (
		<>
			<Header ref={toggleMobMenuBtnRef} onMenuOpen={handleMenuOpen} />

			<div className="container d-flex">
				<LeftMenu ref={leftMenuRef} />

				<div id="page-content" className="d-flex flex-column jc-between">
					<Suspense fallback={null}>
						<Route exact path="/" component={ProfileDirections} />
						<Route path="/lesson/:lessonId" component={Lesson} />
						<Route path="/profile" exact component={Profile} />
						<Route path="/test/:lessonId" component={Test} />
						<Route path="/quest/:lessonId" component={Quest} />

						<Route
							path="/chat"
							render={({ match: { url } }) => (
								<>
									<Route path={url} exact component={Chat} />
									<Route path={`${url}/:chatId`} component={Chat} />
								</>
							)}
						/>

						<Route
							path="/questions"
							render={({ match: { url } }) => (
								<>
									<Route path={url} exact component={Questions} />
									<Route path={`${url}/:questionId`} component={QuestionsIn} />
								</>
							)}
						/>

						<Route
							path="/homework"
							render={({ match: { url } }) => (
								<>
									<Route
										path={`${url}/:homeworkId`}
										exact
										component={Homework}
									/>
									<Route
										path={`${url}/view/:homeworkId`}
										component={ViewHomework}
									/>
								</>
							)}
						/>

						<Route
							path="/my"
							render={({ match: { url } }) => (
								<>
									<Route
										path={`${url}/courses/:directionId`}
										component={ProfileCourses}
									/>
									<Route
										path={`${url}/modules/:courseId`}
										component={ProfileModules}
									/>
								</>
							)}
						/>

						<Route
							path="/catalog"
							render={({ match: { url } }) => (
								<>
									<Route path={url} exact component={CatalogDirections} />
									<Route
										path={`${url}/courses/:directionId`}
										component={CatalogCourses}
									/>
									<Route
										path={`${url}/modules/:courseId`}
										component={CatalogModules}
									/>
								</>
							)}
						/>

						<Footer />
					</Suspense>
				</div>
			</div>
		</>
	)
}
LkFlow.propTypes = {
	location: PropTypes.object,
}

export default withRouter(LkFlow)
