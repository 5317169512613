import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size, ...props }) => {
	return (
		<svg width={size} height={size} viewBox="0 0 512 512" {...props}>
			<path d="M483.351 209.652L75.003 5.518C58.817-2.574 39.965-1.723 24.576 7.789 9.188 17.301 0 33.778 0 51.867v408.267c0 18.089 9.188 34.567 24.576 44.078 15.675 9.689 34.545 10.211 50.427 2.271L483.351 302.35C501.022 293.516 512 275.756 512 256.001s-10.978-37.515-28.649-46.349zm-17.887 56.92L57.116 470.706c-5.324 2.663-9.823.523-11.51-.519-1.685-1.041-5.606-4.106-5.606-10.053V51.867c0-5.946 3.921-9.011 5.606-10.053 3.352-2.073 7.547-2.5 11.51-.519l408.348 204.133c5.899 2.95 6.536 8.376 6.536 10.572 0 2.197-.637 7.623-6.536 10.572z" />
		</svg>
	)
}
SvgComponent.propTypes = {
	size: PropType.number,
}

export default SvgComponent
