import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size = 14, color = "#fff", ...props }) => (
	<svg viewBox="0 0 512 512" width={size} height={size} {...props}>
		<path
			fill={color}
			d="M512 256c0 50.531-15 99.676-43.375 142.117-6.137 9.184-18.559 11.649-27.742 5.512-9.184-6.14-11.649-18.563-5.512-27.742C459.336 340.043 472 298.59 472 256c0-119.102-96.898-216-216-216S40 136.898 40 256s96.898 216 216 216c39.504 0 78.145-10.758 111.738-31.105 9.446-5.723 21.746-2.704 27.469 6.746 5.723 9.445 2.703 21.742-6.746 27.464C348.617 499.242 302.813 512 256 512c-68.379 0-132.668-26.629-181.02-74.98C26.63 388.668 0 324.379 0 256S26.629 123.332 74.98 74.98C123.332 26.63 187.621 0 256 0s132.668 26.629 181.02 74.98C485.37 123.332 512 187.621 512 256zM256.008 380c-11.047 0-20 8.953-20 20s8.953 20 20 20c11.043 0 20-8.953 20-20s-8.957-20-20-20zm41.988-84.54a106.9 106.9 0 0065.02-98.476c0-.336-.008-.672-.024-1.007C362.445 137.449 314.66 90 256.008 90c-58.996 0-106.992 47.996-106.992 106.988 0 11.047 8.953 20 20 20s20-8.953 20-20c0-36.937 30.05-66.988 66.992-66.988 36.937 0 66.988 30.05 66.988 66.988 0 .274.004.54.016.809a66.962 66.962 0 01-40.723 60.879c-27.684 11.816-46.281 40.48-46.281 71.324 0 11.047 8.953 20 20 20 11.043 0 20-8.953 20-20 0-15.043 8.836-28.922 21.988-34.54zm0 0"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
