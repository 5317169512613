import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size = 14, color = "#fff", ...props }) => (
	<svg width={size} height={size} viewBox="0 0 63.97 59.94" {...props}>
		<path
			data-name="-e-Shape 91 copy 7"
			d="M62.76 26.61a3.907 3.907 0 00-2.23-6.7l-15.16-2.15a4.037 4.037 0 01-3.02-2.15L35.57 2.17a4.054 4.054 0 00-7.2 0l-6.78 13.44a4 4 0 01-3.02 2.15L3.41 19.91a3.9 3.9 0 00-2.22 6.7l10.96 10.46a3.894 3.894 0 011.16 3.48l-2.59 14.77a4 4 0 005.83 4.15l13.55-6.98a4.11 4.11 0 013.74 0l13.56 6.98a4 4 0 005.82-4.15l-2.59-14.77a3.894 3.894 0 011.16-3.48z"
			fill={color}
			fillRule="evenodd"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
