import PropTypes from "prop-types"
import React, { useState } from "react"
import { changeBodyClass } from "../global/data"

const initialValue = {
	authToken: null,
	user: {},
	theme: null,
	unreadMessages: null,
}

export const AppContext = React.createContext()

export const AppProvider = ({ children }) => {
	const [state, setState] = useState(initialValue)

	const login = (user, authToken) => {
		localStorage.setItem("authToken", authToken)
		localStorage.setItem("user", JSON.stringify(user))

		setState({ ...state, user, authToken })
	}

	const updateUserInfo = user => {
		localStorage.setItem("user", JSON.stringify(user))

		setState({ ...state, user })
	}

	const reset = () => {
		localStorage.clear()

		setState(initialValue)
	}

	const cTheme = theme => {
		localStorage.setItem("theme", theme)
		changeBodyClass()
	}

	const setUnreadMessages = unreadMessages => {
		setState({ ...state, unreadMessages })
	}

	return (
		<AppContext.Provider
			value={{
				state,
				login,
				reset,
				updateUserInfo,
				cTheme,
				setUnreadMessages,
			}}>
			{children}
		</AppContext.Provider>
	)
}
AppProvider.propTypes = {
	children: PropTypes.node,
}
