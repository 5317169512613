import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link } from "react-router-dom"
import Api from "./../../api"
import iconKey from "../../static/img/icon/key.svg"
import iconMail from "../../static/img/icon/mail.svg"
import Logo from "../../static/img/Logo"
import "./Auth.scss"
import img from "./imgs/img.svg"

const Registration = () => {
	const [errorObj, setErrorObj] = useState({})
	const [isReg, setIsReg] = useState(false)

	const { register, handleSubmit } = useForm({})

	/**
	 * Регистрация
	 * @param {object} data
	 */
	const onSubmit = data => {
		const { email, password, password2 } = data

		let errObj = {}

		if (password.length < 6)
			errObj = { ...errObj, password: "Не менее 6 символов" }
		if (password !== password2)
			errObj = { ...errObj, password2: "Пароли не совпадают" }

		setErrorObj(errObj)

		if (Object.keys(errObj).length === 0) {
			Api.post("/auth/register", {
				email,
				password,
			}).then(res => {
				const { error, data } = res.data

				if (!error) {
					setIsReg(true)
				} else {
					setErrorObj(data)
				}
			})
		}
	}

	return (
		<div className="page-auth scroll-y">
			<div className="container">
				<div className="row">
					<div className="d-flex flex-column l-side col-lg-6 col-xxl-6">
						<Logo className="logo" />

						<LazyLoadImage src={img} className="img d-none d-lg-block" />
					</div>

					<div className="r-side col-lg-6 col-xxl-4">
						<div className="switchers">
							<Link to="/" className="switcher">
								Вход
							</Link>
							<Link to="/reg" className="switcher _active">
								Регистрация
							</Link>
						</div>

						{isReg ? (
							<>
								<p>
									Мы отправили письмо на Ваш E-mail для подтверждения почты.
								</p>
							</>
						) : (
							<form onSubmit={handleSubmit(onSubmit)}>
								<label className="field">
									Электронная почта
									<input
										ref={register}
										type="email"
										name="email"
										placeholder="@mail.ru"
										className="input"
									/>
									<img src={iconMail} alt="" className="icon pos-abs-y" />
									{errorObj.email && (
										<div className="error-text">{errorObj.email}</div>
									)}
								</label>

								<label className="field">
									Пароль
									<input
										ref={register}
										type="password"
										placeholder="•••••••••"
										name="password"
										className="input"
									/>
									<img src={iconKey} alt="" className="icon pos-abs-y" />
									{errorObj.password && (
										<div className="error-text">{errorObj.password}</div>
									)}
								</label>

								<label className="field">
									Повторите пароль
									<input
										ref={register}
										type="password"
										placeholder="•••••••••"
										name="password2"
										className="input"
									/>
									<img src={iconKey} alt="" className="icon pos-abs-y" />
									{errorObj.password2 && (
										<div className="error-text">{errorObj.password2}</div>
									)}
								</label>

								<label className="agree">
									<input
										type="checkbox"
										disabled
										checked
										className="form-checkbox pos-abs-y"
									/>
									Принимаю условия{" "}
									<a href="#" target="_blank" rel="noreferrer">
										обработки данных
									</a>
								</label>

								<button type="submit" className="btn btn-danger btn-block">
									Зарегистрироваться
								</button>
							</form>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Registration
