import React from "react"
import { Redirect, Route } from "react-router"
import AuthScreen from "./Auth"
import RegistrationScreen from "./Registration"

const AuthFlow = () => (
	<>
		<Route path="/" exact component={AuthScreen} />
		<Route path="/reg" component={RegistrationScreen} />

		<Redirect to="/" />
	</>
)

export default AuthFlow
