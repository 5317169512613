import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size, color, ...props }) => (
	<svg viewBox="0 0 159.822 159.822" width={size} height={size} {...props}>
		<path
			fill={color}
			d="M76.715 36.172a7.5 7.5 0 007.5-7.5C84.215 21.133 90.349 15 97.887 15h21.179a7.5 7.5 0 000-15H97.887c-15.81 0-28.672 12.862-28.672 28.672a7.5 7.5 0 007.5 7.5zM117.458 59.627H40.756a7.5 7.5 0 000 15h28.46v77.695a7.5 7.5 0 0015 0V74.627h33.243a7.5 7.5 0 10-.001-15z"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
