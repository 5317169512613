import React from "react"
import ReactDOM from "react-dom"
import MindflexApp from "./App"
import * as serviceWorker from "./serviceWorker"
import "./static/css/style.scss"

ReactDOM.render(
	<React.StrictMode>
		<MindflexApp />
	</React.StrictMode>,
	document.getElementById("root")
)

serviceWorker.unregister()
