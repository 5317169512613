import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size = 14, color = "#fff", ...props }) => (
	<svg viewBox="0 0 512 512" width={size} height={size} {...props}>
		<path
			fill={color}
			d="M506.188 236.413L297.798 26.65c-.267-.27-.544-.532-.826-.786-22.755-20.431-57.14-20.504-79.982-.169-.284.253-.56.514-.829.782L5.872 236.352c-7.818 7.804-7.831 20.467-.028 28.285 7.804 7.818 20.467 7.83 28.284.028L50 248.824v172.684c0 44.112 35.888 80 80 80h72c11.046 0 20-8.954 20-20v-163h70v163c0 11.046 8.954 20 20 20h70c44.112 0 80-35.888 80-80 0-11.046-8.954-20-20-20s-20 8.954-20 20c0 22.056-17.944 40-40 40h-50v-163c0-11.046-8.954-20-20-20H202c-11.046 0-20 8.954-20 20v163h-52c-22.056 0-40-17.944-40-40v-212c0-.2-.003-.399-.009-.597L243.946 55.26a19.984 19.984 0 0125.947.055L422 208.425v113.083c0 11.046 8.954 20 20 20s20-8.954 20-20v-72.82l15.812 15.917A19.934 19.934 0 00492 270.509a19.94 19.94 0 0014.096-5.812c7.836-7.785 7.878-20.448.092-28.284z"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
