import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size = 14, color = "#fff", ...props }) => (
	<svg height={size} viewBox="0 0 512 512" width={size} {...props}>
		<path
			fill={color}
			d="M492 321c-1.91 0-3.75.285-5.5.785-1.75-.5-3.59-.785-5.5-.785H347a19.99 19.99 0 00-16.273 8.375L306.707 363H202.293l-24.02-33.625A20.002 20.002 0 00161.996 321H70V220c0-11.027 8.973-20 20-20h332c11.027 0 20 8.973 20 20v21c0 11.047 8.953 20 20 20s20-8.953 20-20v-21c0-17.758-7.762-33.734-20.063-44.73.028-.422.063-.84.063-1.27v-34c0-17.73-7.734-33.68-20-44.672V60c0-33.086-26.914-60-60-60H130C96.914 0 70 26.914 70 60v35.328C57.734 106.32 50 122.27 50 140v30c0 1.66.223 3.262.605 4.805C37.992 185.809 30 201.985 30 220v101h-1c-1.55 0-3.05.191-4.5.527A19.87 19.87 0 0020 321c-11.047 0-20 8.953-20 20v91c0 44.113 35.887 80 80 80h352c44.113 0 80-35.887 80-80v-91c0-11.047-8.953-20-20-20zM110 60c0-11.027 8.973-20 20-20h252c11.027 0 20 8.973 20 20v20H110zm-20 80c0-11.027 8.973-20 20-20h292c11.027 0 20 8.973 20 20v20H90zm342 332H80c-22.055 0-40-17.945-40-40v-71h111.707l24.02 33.625A19.99 19.99 0 00192 403h125a19.99 19.99 0 0016.273-8.375L357.293 361H472v71c0 22.055-17.945 40-40 40zm0 0"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
