/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react"
import { hot } from "react-hot-loader/root"
import { BrowserRouter, Switch } from "react-router-dom"
import Api from "./api"
import Spinner from "./components/Spinner/Spinner"
import { AppContext, AppProvider } from "./context/AppContext"
import { changeBodyClass } from "./global/data"
import AuthFlow from "./screens/Auth/AuthFLow"
import LkFlow from "./screens/Lk/LkFlow"

const Root = () => {
	const { state, login, reset } = useContext(AppContext)
	const [isLoading, setIsLoading] = useState(false)

	const tryLogin = async () => {
		const authToken = localStorage.getItem("authToken")

		if (authToken) {
			try {
				const res = await Api.get("/profile", {
					headers: { Authorization: `Bearer ${authToken}` },
				})
				const { error, data } = res.data

				!error ? login(data, authToken) : reset()
			} catch (err) {
				console.error(err)
			}
		}

		setTimeout(() => {
			setIsLoading(true)
		}, 300)
	}

	const checkTheme = () => {
		changeBodyClass()
	}

	useEffect(() => {
		checkTheme()
		tryLogin()
	}, [])

	if (!isLoading) {
		return (
			<div style={{ height: "100vh" }} className="d-flex ai-center jc-center">
				<Spinner width={200} height={200} />
			</div>
		)
	}

	return state.authToken ? <LkFlow /> : <AuthFlow />
}

const MindflexApp = () => (
	<AppProvider>
		<BrowserRouter>
			<Switch>
				<Root />
			</Switch>
		</BrowserRouter>
	</AppProvider>
)

export default hot(MindflexApp)
