import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size, color, ...props }) => (
	<svg height={size} viewBox="0 -39 512 512" width={size} {...props}>
		<path
			fill={color}
			d="M504.094 11.86C497.84 4.21 488.473 0 477.714 0c-5.847 0-12.042 1.23-18.41 3.664L25.907 169.105c-23 8.778-26.097 21.95-25.898 29.02s4.039 20.047 27.5 27.512c.14.043.281.086.422.125l89.898 25.726 48.617 139.024c6.63 18.953 21.508 30.726 38.836 30.726 10.926 0 21.672-4.578 31.078-13.234l55.606-51.2 80.652 64.942c.008.008.02.012.028.02l.765.617.215.168c8.965 6.953 18.75 10.625 28.309 10.629h.003c18.676 0 33.547-13.825 37.88-35.215l71.011-350.64c2.852-14.075.461-26.669-6.734-35.466zM147.902 246.601l173.442-88.606L213.348 272.75a14.975 14.975 0 00-3.641 6.684l-20.824 84.351zm68.133 139.332c-.719.66-1.441 1.25-2.164 1.793l19.32-78.25 35.145 28.3zm265.39-344.567l-71.01 350.645c-.685 3.355-2.868 11.164-8.481 11.164-2.774 0-6.258-1.512-9.825-4.258l-91.39-73.586a.173.173 0 00-.043-.031l-54.38-43.79 156.177-165.949a15 15 0 00-17.746-23.636L127.859 223.152l-91.12-26.07 433.265-165.39c3.66-1.399 6.215-1.692 7.71-1.692.919 0 2.552.11 3.157.855.797.973 1.813 4.29.555 10.512zm0 0"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
