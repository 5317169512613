import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size = "20px", color = "rgba(29,40,56, .5)", ...props }) => (
	<svg viewBox="0 0 512 512" width={size} height={size} {...props}>
		<path
			fill={color}
			d="M366.905 108.016h-141.91c-11.048 0-20.003 8.955-20.003 20.003s8.955 20.003 20.003 20.003h141.91c11.048 0 20.003-8.955 20.003-20.003s-8.956-20.003-20.003-20.003zM366.905 188.027h-141.91c-11.048 0-20.003 8.955-20.003 20.003s8.955 20.003 20.003 20.003h141.91c11.047 0 20.003-8.955 20.003-20.003s-8.955-20.003-20.003-20.003zM286.004 268.039h-61.009c-11.048 0-20.003 8.955-20.003 20.003s8.955 20.003 20.003 20.003h61.009c11.048 0 20.003-8.955 20.003-20.003s-8.955-20.003-20.003-20.003z"
		/>
		<path
			fill={color}
			d="M448.028 272.039c11.048 0 20.003-8.955 20.003-20.003V80.012C468.031 35.893 432.137 0 388.019 0H203.992c-44.094 0-79.971 35.853-80.012 79.938-44.118 0-80.012 35.893-80.012 80.012v272.039c0 44.118 35.893 80.012 80.012 80.012h194.028c44.118 0 80.012-35.893 80.012-80.012v-.608c39.414-4.938 70.01-38.662 70.01-79.389 0-11.048-8.955-20.003-20.003-20.003s-20.003 8.955-20.003 20.003c0 22.054-17.942 40.001-39.996 40.006l-184.027.045h-.009c-10.685 0-20.73-4.16-28.285-11.715-7.558-7.556-11.721-17.604-11.721-28.291V80.012c0-22.059 17.947-40.006 40.006-40.006H388.02c22.059 0 40.006 17.947 40.006 40.006v172.025c-.001 11.047 8.954 20.002 20.002 20.002zM203.992 432.047h.02l154.002-.038c-.012 22.049-17.954 39.984-40.006 39.984H123.981c-22.059 0-40.006-17.947-40.006-40.006V159.948c0-22.059 17.947-40.006 40.006-40.006v232.094c0 21.375 8.325 41.471 23.441 56.583 15.113 15.11 35.2 23.428 56.57 23.428z"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
