/* eslint-disable react/display-name */
import PropTypes from "prop-types"
import React, {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link, useLocation } from "react-router-dom"
import Api from "./../api"
import SearchResult from "../components/SearchResult/SearchResult"
import { AppContext } from "../context/AppContext"
import useDebounce from "../hooks/useDebounce"
import { IconClose, IconExit, IconLoop, Moon, Sun } from "../static/icons"
import defaultAvatar from "../static/img/default_avatar.svg"
import Logo from "../static/img/Logo"

const Header = forwardRef(({ onMenuOpen }, ref) => {
	const location = useLocation()

	const { state, reset, cTheme } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [searchList, setSearchList] = useState([])
	const [search, setSearch] = useState("")
	const [iconTheme, setIconTheme] = useState(null)

	const formRef = useRef()
	const btnRef = useRef()

	const handleFormOpen = e => {
		e.preventDefault()
		formRef.current.classList.add("is-open")
	}

	const handleFormClose = e => {
		e.preventDefault()
		formRef.current.classList.remove("is-open")
	}

	useImperativeHandle(ref, () => ({
		toggleClass() {
			btnRef.current.classList.toggle("_active")
		},
		removeClass() {
			btnRef.current.classList.remove("_active")
		},
	}))

	const getSearchList = () => {
		Api.get(`/course/search?q=${search}`, { headers: { Authorization } }).then(
			res => {
				const { error, data } = res.data

				if (!error) {
					setSearchList(data.results)
				} else {
					console.error(data)
					alert("Ошибка")
				}
			}
		)
	}

	const themeChange = () => {
		let theme = localStorage.getItem("theme")
		theme === "_light" ? cTheme("_dark") : cTheme("_light")
		theme === "_light" ? setIconTheme("_dark") : setIconTheme("_light")
	}

	const debouncedSearch = useDebounce(search, 300)
	useEffect(() => {
		debouncedSearch.length >= 3 && getSearchList()
	}, [debouncedSearch])

	useEffect(() => {
		search.length <= 2 && setSearchList([])
	}, [search])

	useEffect(() => {
		setSearchList([])
		setSearch("")
	}, [location.pathname])

	return (
		<header id="page-header" className="bg-bg">
			<div className="container d-flex jc-between ai-center">
				<div className="left-side">
					<button
						ref={btnRef}
						className="d-block d-xl-none pos-abs-y mob-menu-toggle bg-dop"
						onClick={onMenuOpen}>
						<span className="pos-abs" />
					</button>

					<Link to="/" className="logo">
						<Logo />
					</Link>
				</div>

				<div className="right-side d-flex jc-between ai-center">
					<form
						ref={formRef}
						onSubmit={e => e.preventDefault()}
						className="form d-flex">
						<button className="loop-btn button">
							<IconLoop size={20} className="pos-abs" />
						</button>

						<input
							className="form-input"
							value={search}
							onChange={e => setSearch(e.target.value)}
							placeholder="Поиск"
						/>

						{searchList.length > 0 && <SearchResult data={searchList} />}

						<button
							className="d-block d-md-none pos-abs-y close-btn"
							onClick={handleFormClose}>
							<IconClose size={18} className="pos-abs" />
						</button>
					</form>

					<button
						className="d-block d-md-none pos open-form-btn bg-dop"
						onClick={handleFormOpen}>
						<IconLoop size={20} className="pos-abs" />
					</button>

					<Link to="/profile" className="user">
						<LazyLoadImage
							src={state.user?.avatarThumb || defaultAvatar}
							className="avatar pos-abs-y image-cover"
						/>

						<span className="d-none d-lg-block name">
							{state.user?.first_name || " "}
						</span>
					</Link>

					<button className="exit d-none d-lg-block" onClick={() => reset()}>
						<IconExit size={20} />
					</button>

					<button className="ml-2" onClick={themeChange}>
						{iconTheme ? (
							iconTheme === "_dark" ? (
								<Moon size={20} />
							) : (
								<Sun size={20} color="#7f7f7f" />
							)
						) : localStorage.getItem("theme") === "_dark" ? (
							<Moon size={20} />
						) : (
							<Sun size={20} color="#7f7f7f" />
						)}
					</button>
				</div>
			</div>
		</header>
	)
})
Header.propTypes = {
	onMenuOpen: PropTypes.func.isRequired,
}

export default Header
