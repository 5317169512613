import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size = 14, color = "#fff" }) => (
	<svg width={size} height={size} viewBox="0 0 1049 1280">
		<path
			fill={color}
			d="M601.5 1.1C527.5 5.7 454.6 23 387 52c-28.4 12.1-81.7 40.7-98.5 52.8-2.2 1.5-10.8 7.7-19.1 13.6-17.9 12.6-36.6 27.5-52.1 41.2-16.3 14.4-37.5 34.4-39.8 37.5-1.1 1.5-6.3 7.4-11.5 13.1C43.7 344.4-15.5 525.5 3.6 707c15.3 145.9 79.6 280.9 183.5 384.9 66.5 66.5 142.9 115.6 231.5 148.6 40.6 15.1 86.5 26.8 130.4 33 34.1 4.8 51 5.9 90 5.9 37.4.1 51.9-.8 81-4.5 8.5-1.1 18.2-2.3 21.5-2.5 3.3-.3 12.1-1.9 19.5-3.5 70.7-16 109.7-29.3 162.5-55.4 38.6-19.1 67.2-36.6 100-61.2 26.1-19.6 28.5-22.3 23.7-27.1-1.7-1.7-4.3-2-23.3-2.5-174.2-5.1-338.6-83.9-454.5-217.7C451 768.2 397.7 584.4 424.1 404 445.9 254.6 521 118.6 636.5 19.1 648.1 9.1 648 9.3 648 5.9c0-5.2-2.2-5.9-18.6-5.8-8.2.1-20.7.6-27.9 1z"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
