export const BODY = document.body

export const changeBodyClass = () => {
	const theme = localStorage.getItem("theme")
	const { classList } = BODY

	if (theme === "_dark") {
		classList.remove("_light")
		classList.add("_dark")
	} else {
		classList.remove("_dark")
		classList.add("_light")
	}
}
