import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link } from "react-router-dom"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { AppContext } from "../../context/AppContext"
import iconKey from "../../static/img/icon/key.svg"
import iconProfile from "../../static/img/icon/user.svg"
import Logo from "../../static/img/Logo"
import iconVk from "../../static/img/soc/vk.svg"
import "./Auth.scss"
import Api from "./../../api"
import img from "./imgs/img.svg"

const Auth = () => {
	const { state, login } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const { register, handleSubmit } = useForm({})
	const [errorObj, setErrorObj] = useState({})

	/**
	 * Авторизация
	 * @param {object} data
	 */
	const onSubmit = data => {
		const { email, password } = data

		let errObj = {}

		if (password.length < 6)
			errObj = { ...errObj, password: "Не менее 6 символов" }

		setErrorObj(errObj)

		if (Object.keys(errObj).length === 0) {
			const sendData = {
				email,
				password,
			}

			Api.post("/auth/login", sendData).then(res => {
				const { error, data } = res.data

				if (!error) {
					setErrorObj({})
					login(data.profile, data.token)
				} else {
					setErrorObj(data)
				}
			})
		}
	}

	/**
	 * Авторизация через vk
	 */
	const useAuthVk = () => {
		// eslint-disable-next-line no-undef
		VK.Auth.login(r => {
			if (r.session) {
				const { id, last_name, first_name } = r.session.user

				Api.post(
					"/auth/vk",
					{
						last_name,
						first_name,
						id,
					},
					{ headers: { Authorization } }
				).then(res => {
					const { error, data } = res.data

					if (!error) {
						login(data.profile, data.token)
					} else {
						console.error("Ошибка авторизации")
					}
				})
			} else {
				console.error("Ошибка авторизации")
			}
		}, 4)
	}

	/**
	 * Авторизация через Facebook
	 */
	// const useAuthFacebook = data => {
	// 	console.log(data)
	// }

	// const facebookBtnClicked = () => {
	// 	console.log("clicked")
	// }

	return (
		<div className="page-auth scroll-y">
			<div className="container">
				<div className="row">
					<div className="d-flex flex-column l-side col-lg-6 col-xxl-6">
						<Logo className="logo" />

						<LazyLoadImage src={img} className="img d-none d-lg-block" />
					</div>

					<div className="r-side col-lg-6 col-xxl-4">
						<div className="switchers">
							<Link to="/" className="switcher _active">
								Вход
							</Link>
							<Link to="/reg" className="switcher">
								Регистрация
							</Link>
						</div>

						<form onSubmit={handleSubmit(onSubmit)}>
							<label className="field border-1">
								Электронная почта
								<input
									ref={register}
									type="email"
									name="email"
									placeholder="@mail.ru"
									className="input"
								/>
								<img src={iconProfile} alt="" className="icon pos-abs-y" />
							</label>

							<label className="field border-1">
								Пароль
								<input
									ref={register}
									type="password"
									placeholder="•••••••••"
									name="password"
									className="input"
								/>
								<img src={iconKey} alt="" className="icon pos-abs-y" />
								{errorObj.password && (
									<div className="error-text">{errorObj.password}</div>
								)}
							</label>

							<button className="btn btn-danger btn-block">Войти</button>
						</form>

						<p>Или войти черз соц. сети</p>

						<div className="row row-xs">
							<div className="col-12">
								<button
									className="soc-auth-btn bg-dop border-radius"
									onClick={useAuthVk}>
									<img
										src={iconVk}
										alt="vkontakte icon"
										className="icon pos-abs-y"
									/>
									Вконтакте
								</button>
							</div>

							<div className="col-6">
								{/* <FacebookLogin
									appId="268678454384314"
									autoLoad
									callback={useAuthFacebook}
									render={renderProps => {
										console.log(renderProps)

										return (
											<button onClick={facebookBtnClicked} className="soc-auth-btn">
												<img src={iconFb} alt="facebook icon" className="icon pos-abs-y" />
												Facebook
											</button>
										)
									}}
								/> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Auth
