import PropTypes from "prop-types"
import React, { forwardRef, useContext, useImperativeHandle, useRef } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { AppContext } from "../../context/AppContext"
import {
	IconChat,
	IconExit,
	IconHome,
	IconMyCourses,
	IconQuestionMark,
	IconUser,
} from "../../static/icons"
import "./LeftMenu.scss"

const LeftMenu = forwardRef((props, ref) => {
	const { state, reset } = useContext(AppContext)

	const location = useLocation()
	const menuRef = useRef()

	const ROUTE_LIST = [
		{
			name: "Мое обучение",
			icon: IconMyCourses,
			path: "/",
			pathRelate: ["", "my", "test", "quest", "homework", "lesson"],
		},
		{
			name: "Каталог",
			icon: IconHome,
			path: "/catalog",
			pathRelate: ["catalog"],
		},
		{
			name: "Профиль",
			icon: IconUser,
			path: "/profile",
			pathRelate: ["profile"],
		},
		{
			name: "Онлайн чат",
			icon: IconChat,
			path: "/chat",
			pathRelate: ["chat"],
		},
		{
			name: "Вопросы",
			icon: IconQuestionMark,
			path: "/questions",
			pathRelate: ["questions"],
		},
	]

	useImperativeHandle(ref, () => ({
		toggleMenu() {
			menuRef.current.classList.toggle("_visible")
		},
		hideMenu() {
			menuRef.current.classList.remove("_visible")
		},
	}))

	return (
		<div id="left-menu" ref={menuRef} className="left-menu bg-dop d-flex flex-column jc-between">
			<div className="menu-wrap">
				{ROUTE_LIST.map(i => {
					const Icon = i.icon

					return (
						<NavLink
							key={i.name}
							to={i.path}
							className="link d-flex ai-center jc-between"
							activeClassName={
								i.pathRelate.includes(location.pathname.split("/")[1]) ? "_active" : ""
							}
						>
							<span className={`icon ${i.path === location.pathname && "_active"} pos-abs-y`}>
								<Icon size={19} className="pos-abs" />
							</span>

							{i.name}

							{i.pathRelate.includes("chat") && state.unreadMessages ? (
								<span className="badge ml-2 h7">{state.unreadMessages}</span>
							) : null}
						</NavLink>
					)
				})}

				<button className="d-block d-lg-none link" onClick={() => reset()}>
					<span className="icon pos-abs-y">
						<IconExit size={19} className="pos-abs" />
					</span>
					Выход
				</button>
			</div>

			{/* <div>
				<a
					href="https://ru.reactjs.org/docs/hooks-rules.html"
					target="_blank"
					rel="noreferrer"
					className="download-link"
				>
					<img src={iconPlayMarket} className="icon pos-abs-y" />
					Доступно <br /> в Google Play
				</a>

				<a
					href="https://ru.reactjs.org/docs/hooks-rules.html"
					target="_blank"
					rel="noreferrer"
					className="download-link"
				>
					<img src={iconAppStore} className="icon pos-abs-y" />
					Загрузите <br /> из AppStore
				</a>
			</div> */}
		</div>
	)
})
LeftMenu.propTypes = {
	location: PropTypes.object,
}

export default LeftMenu
