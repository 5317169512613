import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size, color, ...props }) => (
	<svg viewBox="0 0 184.313 184.313" width={size} height={size} {...props}>
		<path
			fill={color}
			d="M180.323 147.777l-53.048-45.373 55.314-66.784a7.5 7.5 0 00-11.553-9.568l-60.022 72.469a7.4 7.4 0 00-.784 1.156V30.836a7.5 7.5 0 00-7.5-7.5h-9.521c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h2.021v93.253L13.424 26.236a7.5 7.5 0 00-11.848 9.199l95.229 122.641a7.498 7.498 0 008.356 2.496 7.5 7.5 0 005.068-7.095v-46.55a7.468 7.468 0 001.686 2.079l58.658 50.171a7.467 7.467 0 004.871 1.8 7.5 7.5 0 004.879-13.2z"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
