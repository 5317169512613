import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size = 14, color = "#fff", ...props }) => (
	<svg viewBox="0 0 512 512" width={size} height={size} {...props}>
		<path
			fill={color}
			d="M302 0C186.206 0 92 94.206 92 210c0 50.944 18.234 97.709 48.519 134.112L5.906 477.81c-7.837 7.784-7.88 20.447-.097 28.284A19.94 19.94 0 0020 512a19.94 19.94 0 0014.093-5.81L168.89 372.31C205.148 402.098 251.523 420 302 420c36.026 0 71.576-9.283 102.804-26.846 9.628-5.414 13.043-17.608 7.628-27.236-5.414-9.627-17.608-13.044-27.236-7.628C359.942 372.493 331.173 380 302 380c-93.738 0-170-76.262-170-170S208.262 40 302 40s170 76.262 170 170c0 29.531-7.683 58.609-22.217 84.091-5.473 9.595-2.131 21.809 7.463 27.282 9.595 5.472 21.809 2.131 27.282-7.463C502.5 282.4 512 246.469 512 210 512 94.206 417.794 0 302 0z"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.any,
	color: PropType.string,
}

export default SvgComponent
