import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size = "20px", color = "#1d2838", ...props }) => (
	<svg viewBox="0 -10 512 511" width={size} height={size} {...props}>
		<path
			fill={color}
			d="M257 471.5H80c-44.113 0-80-35.887-80-80v-311C0 36.387 35.887.5 80 .5h352c44.113 0 80 35.887 80 80v197c0 11.047-8.953 20-20 20s-20-8.953-20-20v-197c0-22.055-17.945-40-40-40H80c-22.055 0-40 17.945-40 40v311c0 22.055 17.945 40 40 40h177c11.047 0 20 8.953 20 20s-8.953 20-20 20zm-140-100c0-11.047-8.953-20-20-20s-20 8.953-20 20 8.953 20 20 20 20-8.953 20-20zm80 0c0-11.047-8.953-20-20-20s-20 8.953-20 20 8.953 20 20 20 20-8.953 20-20zm80 0c0-11.047-8.953-20-20-20s-20 8.953-20 20 8.953 20 20 20 20-8.953 20-20zm-160-271c0-11.047-8.953-20-20-20s-20 8.953-20 20 8.953 20 20 20 20-8.953 20-20zm80 0c0-11.047-8.953-20-20-20s-20 8.953-20 20 8.953 20 20 20 20-8.953 20-20zm80 0c0-11.047-8.953-20-20-20s-20 8.953-20 20 8.953 20 20 20 20-8.953 20-20zm80 0c0-11.047-8.953-20-20-20s-20 8.953-20 20 8.953 20 20 20 20-8.953 20-20zm80 0c0-11.047-8.953-20-20-20s-20 8.953-20 20 8.953 20 20 20 20-8.953 20-20zm-54.484 385.133l106.625-61.371C502.828 416.382 511 402.266 511 386.5s-8.172-29.883-21.86-37.762l-106.624-61.37c-13.7-7.888-30.047-7.872-43.735.042C325.145 295.297 317 309.398 317 325.13V447.87c0 15.73 8.145 29.832 21.781 37.719 6.856 3.965 14.38 5.945 21.906 5.945 7.497 0 14.993-1.965 21.829-5.902zm-19.954-163.598l106.625 61.371c1.204.692 1.813 1.735 1.813 3.094 0 1.363-.61 2.402-1.813 3.094l-106.625 61.37c-1.25.72-2.511.72-3.757-.003-1.196-.691-1.805-1.73-1.805-3.09V325.13c0-1.36.605-2.399 1.805-3.09.625-.36 1.254-.543 1.882-.543.625 0 1.254.18 1.875.54zm0 0"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
