import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size = 14, color = "#fff", ...props }) => (
	<svg width={size} height={size} viewBox="0 0 48 43" {...props}>
		<path
			d="M24 12.29a11.635 11.635 0 1011.72 11.64A11.692 11.692 0 0024 12.29zm0 19.55a7.915 7.915 0 117.97-7.91A7.95 7.95 0 0124 31.84zM42.37 6.7h-6.89a.586.586 0 01-.52-.31l-1.91-3.98c0-.01-.01-.02-.01-.03A4.319 4.319 0 0029.16 0H18.973a4.31 4.31 0 00-3.876 2.38l-.016.03-1.909 3.98a.582.582 0 01-.516.31H5.625A5.615 5.615 0 000 12.29v25.14a5.607 5.607 0 005.625 5.58H42.37A5.606 5.606 0 0048 37.43V12.29a5.614 5.614 0 00-5.63-5.59zm1.88 30.73a1.874 1.874 0 01-1.88 1.86H5.625a1.873 1.873 0 01-1.875-1.86V12.29a1.867 1.867 0 011.875-1.86h7.031a4.328 4.328 0 003.876-2.38.131.131 0 00.016-.04l1.909-3.98a.582.582 0 01.516-.31H29.16a.586.586 0 01.52.31l1.91 3.98a.227.227 0 00.01.04 4.337 4.337 0 003.88 2.38h6.89a1.867 1.867 0 011.88 1.86v25.14zm-7.5-23.28h3.75v3.72h-3.75v-3.72z"
			fill={color}
			fillRule="evenodd"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
