import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size = 14, color = "#fff", ...props }) => (
	<svg height={size} viewBox="0 0 511 512" width={size} {...props}>
		<path
			fill={color}
			d="M361.5 392v40c0 44.113-35.887 80-80 80h-201c-44.113 0-80-35.887-80-80V80c0-44.113 35.887-80 80-80h201c44.113 0 80 35.887 80 80v40c0 11.047-8.953 20-20 20s-20-8.953-20-20V80c0-22.055-17.945-40-40-40h-201c-22.055 0-40 17.945-40 40v352c0 22.055 17.945 40 40 40h201c22.055 0 40-17.945 40-40v-40c0-11.047 8.953-20 20-20s20 8.953 20 20zm136.355-170.355l-44.785-44.786c-7.812-7.812-20.476-7.812-28.285 0-7.812 7.809-7.812 20.473 0 28.282L456.641 237H216.5c-11.047 0-20 8.953-20 20s8.953 20 20 20h240.14l-31.855 31.86c-7.812 7.808-7.812 20.472 0 28.28a19.935 19.935 0 0014.14 5.86 19.937 19.937 0 0014.145-5.86l44.785-44.785c19.497-19.496 19.497-51.214 0-70.71zm0 0"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string,
}

export default SvgComponent
